import React, { Component } from "react";
import classes from "./Dropdown.module.css";
import PropTypes from "prop-types";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      top: false,
      left: false
    };
    this.dropdownContainerRef = React.createRef();
  }

  checkBoundary() {
    let rect = this._contentRef.getBoundingClientRect();
    if (rect.x + rect.width > window.innerWidth) {
      this.setState({ left: true });
    }
    if (
			rect.y + rect.height > window.innerHeight &&
			rect.height-rect.y <= rect.y
		) {
			this.setState({ top: true });
		} else {
      this.setState({top: false})
    }
  }

  componentDidMount() {
    this.checkBoundary();
  }

  dropdownBlurHandler = e => {
    if (
      this.props.notCloseInsideClick &&
      this.dropdownContainerRef.current.contains(e.target)
    ) {
      return;
    }

    this.setState({ show: false });
    this.checkBoundary();
    document.removeEventListener("click", this.dropdownBlurHandler);
  };

  dropdownClickHandler = e => {
    if (this.state.show) {
      this.setState({ show: false });
      document.removeEventListener("click", this.dropdownBlurHandler);
      return;
    }
    document.addEventListener("click", this.dropdownBlurHandler);
    this.setState({ show: true }, () => {
      this.checkBoundary();
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.dropdownBlurHandler);
    clearTimeout(this.timeout);
  }

  render() {
    let { ddnClass, btnClass } = this.props;
    let displayClass = classes.Hide,
      dropdownClass = classes.ChatDropdown + " ChatDropdown ";

    let btnClassName = classes.Btn + " " + (btnClass || "");

    if (this.state.show) {
      // iconClass = classes.Open;
      displayClass = "";
      dropdownClass += " " + "ChatDropdownOpen";
    }

    let { isIcon, icon } = this.props;

    return (
      <div
        // onMouseEnter={this.dropdownClickHandler}
        className={dropdownClass}
        onClick={this.dropdownClickHandler}
        ref={this.dropdownContainerRef}
        style={this.props.style}
      >
        {isIcon ? (
          <div style={this.props.btnStyle} className={btnClassName}>
            {/* <FontAwesomeIcon icon={icon} /> */}
          </div>
        ) : (
          <div style={this.props.btnStyle} className={btnClassName}>
            {this.props.name}
            {!this.props.noCaret && <span className={classes.Caret}></span>}
          </div>
        )}
        <div
          className={
            classes.Items + " " + displayClass + " " + (ddnClass || "")
          }
          onClick={e => e.stopPropagation()}
          style={Object.assign(
            {},
            this.props.ddnStyle,
            this.state.top ? { bottom: 0, top: "auto" } : {},
            this.state.left ? { right: 0, left: "auto" } : {}
          )}
          ref={elem => (this._contentRef = elem)}
        >
          <ul>{this.props.items}</ul>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  notCloseInsideClick: PropTypes.bool,
  items: PropTypes.array.isRequired,
  name: PropTypes.node,
  ddnStyle: PropTypes.object,
  ddnClass: PropTypes.string,
  btnStyle: PropTypes.object,
  isIcon: PropTypes.bool,
  icon: PropTypes.object,
  noCaret: PropTypes.bool
};

export default Dropdown;
