import React, { useContext, useEffect } from "react";
import { Image, Placeholder, Icon, Button } from "semantic-ui-react";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";

export default function RoomUserCard(props) {
  let context = useContext(AppContext);
  let { member, tutorId, memberId } = props;

  const acceptInvitation = () => {
    let { invitation } = member;
    let members = context;
    // connection Modified
    let totalActiveConnections = Object.keys(members).filter((memberId) => {
      return (
        members[memberId] && members[memberId].invitationStatus === "ACCEPTED"
      );
    });

    if (
      totalActiveConnections >=
      (context.sessionData.activeRaiseHandLimit
        ? context.sessionData.activeRaiseHandLimit
        : 4)
    ) {
      toast.error(
        "close one of active student's connection to accept new invite"
      );
      return;
    }
    invitation.accept();
  };

  const cancelInvitaion = () => {
    let { invitation } = member;
    invitation.refuse();
  };

  const endInvitationConnection = () => {
    let { rtm } = context;
    rtm.emit("END_INVITATION_CONNECTIION", member);
  };

  // Event hadnlers
  const remoteInviteAcceptedHandler = () => {
    try {
      let { members, setContext } = context;
      let member = members[memberId];

      if (!member) {
        return console.log(
          "CLPS_LIVE_ERROR: Call recieved from member not it the list"
        );
      }

      toast.success(`${member.name} is speaking now...`);
      member = { ...member };
      member.invitationStatus = "ACCEPTED";
      setContext(
        {
          members: { ...members, [memberId]: member },
        },
        () => {
          // console.log(context.members);
        }
      );

      // no need of this  for now
      // rtm.client.emit("INVITE_ACCEPTED", member);
    } catch (err) {
      console.err(err);
    }
  };

  const remoteInviteCancelHandler = () => {
    try {
      // no use case for now
      let { members, setContext } = context;
      let member = members[memberId];

      if (!member) {
        return console.log(
          "CLPS_LIVE_ERROR: Call recieved from member not it the list"
        );
      }

      member = { ...member };
      // toaster for caller cancelled request
      delete member.invitation;
      delete member.invitationStatus;
      setContext({
        members: { ...members, [memberId]: member },
      });
    } catch (err) {
      console.err(err);
    }
  };

  const remoteInviteRemoveHandler = () => {
    try {
      // no use case for now
      let { members, setContext } = context;
      let member = members[memberId];

      if (!member) {
        return console.log(
          "CLPS_LIVE_ERROR: Call recieved from member not it the list"
        );
      }

      toast.error(`${member.name}'s request rejeced`);

      member = { ...member };
      // toaster for caller cancelled request
      delete member.invitation;
      delete member.invitationStatus;
      setContext({
        members: { ...members, [memberId]: member },
      });
    } catch (err) {
      console.err(err);
    }
  };

  const remoteInviteFailureHandler = () => {
    try {
      // no use case for now
      let { members, setContext } = context;
      let member = members[memberId];
      if (!member) {
        return console.log(
          "CLPS_LIVE_ERROR: Call recieved from member not it the list"
        );
      }

      member = { ...member };
      // toaster for caller cancelled request
      delete member.invitation;
      delete member.invitationStatus;
      setContext({
        members: { ...members, [memberId]: member },
      });
    } catch (err) {
      console.err(err);
    }
  };

  // add events here
  useEffect(() => {
    let { member } = props;
    if (!member || !member.invitation) return;

    // console.log("USER_EFFECT", member);

    member.invitation.on(
      "RemoteInvitationAccepted",
      remoteInviteAcceptedHandler
    );
    member.invitation.on("RemoteInvitationCanceled", remoteInviteCancelHandler);
    member.invitation.on("RemoteInvitationRefused", remoteInviteRemoveHandler);
    member.invitation.on("RemoteInvitationFailure", remoteInviteFailureHandler);

    return () => {
      // console.log("USER_EFFECT_CLEANUP");
      member.invitation.removeListener(
        "RemoteInvitationAccepted",
        remoteInviteAcceptedHandler
      );
      member.invitation.removeListener(
        "RemoteInvitationCanceled",
        remoteInviteCancelHandler
      );
      member.invitation.removeListener(
        "RemoteInvitationRefused",
        remoteInviteRemoveHandler
      );
      member.invitation.removeListener(
        "RemoteInvitationFailure",
        remoteInviteFailureHandler
      );
    };
  });

  if (!member)
    return (
      <li key={memberId}>
        <Placeholder className="roomUserPlaceholder">
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </li>
    );

  let { id, name, imageUrl, platform } = member;

  let image =
    imageUrl === "NOT_AVAILABLE" ? (
      <Avatar name={name} className="avatar" />
    ) : (
      <Image className="avatar" avatar src={imageUrl} />
    );

  return (
    <li className="roomUser" key={id}>
      {image}
      <div className="detailContainer">
        <div className="userName">
          <div>
            <div>
              {name}{" "}
              {member.invitationStatus === "ACCEPTED" && (
                <p>is asking question...</p>
              )}
            </div>
            {member.invitationStatus === "ACCEPTED" && (
              <Icon
                color="grey"
                name={context.sessionData.showVideo ? "video" : "microphone"}
                size="small"
              />
            )}
          </div>
          <div className="flexCenter ">
            {member.invitationStatus === "PENDING" && (
              <Button.Group basic size="small" className="mx10">
                <Button icon onClick={acceptInvitation}>
                  <Icon name="check" color="green" />
                </Button>
                <Button icon onClick={cancelInvitaion}>
                  <Icon name="close" color="red" />
                </Button>
              </Button.Group>
            )}
            {member.invitationStatus === "ACCEPTED" && (
              <Button icon basic onClick={endInvitationConnection}>
                <Icon name="close" color="red" />
              </Button>
            )}

            {tutorId === member.id && (
              <span className="badge success mx10"> Admin </span>
            )}
            {platform === "web" ? (
              <Icon name="computer" size="small" />
            ) : platform === "mobile" ? (
              <Icon name="mobile alternate" size="small" />
            ) : null}

            {member.joinedAt && member.joinedAt.getTime() > Date.now() && (
              <span className="badge new">New</span>
            )}
          </div>
        </div>
        {/* <div className="infoText"> is asking question... </div> */}
      </div>
    </li>
  );
}
