import React, { Component, useEffect } from "react";
import ChatInputWithButtons from "../../components/chatInputWithButtons";
import { v4 as uuidv4 } from "uuid";

import classes from "../../Live.module.css";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import ChatMessage from "../../components/chatMessage";
import RtmConnectionStatus from "../../components/RtmConnectionStatus";
export default class Chat extends Component {
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      chats: [],
      connectionState: null,
      connectionStateReason: null,
    };
    this.chatContainerContentRef = React.createRef();
  }

  onSubmitHandler = (text, clearText) => {
    let { rtm } = this.context;
    let { sessionData } = this.context;

    // statically typing current user name and details
    let user = this.context.getUserInfo();

    let chat = {
      _id: uuidv4(),
      message: text,
      type: "MESSAGE",
      sent_at: parseInt(Date.now()),
      user: user,
    };

    this.setState({ chats: [...this.state.chats, chat] });
    clearText();

    // %%------legacy send message  handling----%%
    // if (sessionData.liveVersion == 1) {
    // let oldMessage = [user.name, text].join("%--%--%");
    // rtm.sendChannelMessage(oldMessage, sessionData.channelName);
    // }
    // // %%------legacy send message  handling----%%
    // else {

    // uncomment below old msg
    let oldMessage = [user.name, text].join("%--%--%");
    rtm.sendChannelMessage(oldMessage, sessionData.channelName);

    rtm
      .sendChannelMessage(chat, sessionData.channelName)
      .then(() => {
        //handle sending message here
        // this.setState({ chats: [...this.state.chats, chat] });
        // clearText();
      })
      .catch((err) => {
        // Toast.error(
        //   "Send message to channel " +
        //     params.channelName +
        //     " failed, please open console see more details."
        // );
        console.error(err);
      });
    // }
  };

  appendChat = (chat, cb) => {
    this.setState({ chats: [...this.state.chats, chat] }, cb);
  };

  sendConnectionClosedMessageToTutor = (memberId) => {
    let { rtm, /*  getUserInfo, */ sessionData } = this.context;
    // statically typing current user name and details
    // let user = getUserInfo();

    let chat = {
      _id: uuidv4(),
      message: "Connection closed",
      memberId: memberId,
      type: "RAISE_HAND_CONNECTION_CLOSED",
      sent_at: parseInt(Date.now()),
      // user: user,
    };

    rtm
      .sendChannelMessage(chat, sessionData.channelName)
      .then(() => {})
      .catch((err) => {
        //  display connection closed toaster here
        console.error(err);
      });
  };

  // IsJsonString = (item) => {
  //   item = typeof item !== "string" ? JSON.stringify(item) : item;

  //   try {
  //     item = JSON.parse(item);
  //   } catch (e) {
  //     console.error("fata");
  //     return false;
  //   }

  //   if (typeof item === "object" && item !== null) {
  //     return true;
  //   }

  //   return false;
  // };

  // component did mount
  componentDidMount() {
    let { rtm, userType, sessionData } = this.context;
    // this.appendChat({
    //   _id: uuidv4(),
    //   message: `You Joined`,
    //   type: "USER_JOINED",
    // });

    // attaching listners
    rtm.on("ConnectionStateChanged", async (newState, reason) => {
      // console.log("RTMConnectionStateChanged");

      if (newState === "CONNECTED") {
        setTimeout(() => {
          this.setState({ connectionState: null, connectionStateReason: null });
        }, 2000);
        // get latest attendee count
        rtm.client
          .getChannelMemberCount([sessionData.channelName])
          .then((currentCount) => {
            this.context.setContext({
              membersCount: currentCount[sessionData.channelName],
            });
          });
      } else if (newState === "DISCONNECTED") {
        rtm._logined = false;
      } else if (newState === "ABORTED") {
        await rtm.logout();
        rtm._logined = false;
      }

      this.setState({
        connectionState: newState,
        connectionStateReason: reason,
      });
    });

    rtm.on("appendToChat", (chat) => {
      this.appendChat(chat);
    });

    if (userType === "tutor") {
      rtm.on("END_INVITATION_CONNECTIION", (member) => {
        let { rtm, sessionData, getUserInfo } = this.context;
        // console.log("sending connection close message");
        // statically typing current user name and details
        let user = getUserInfo();

        let chat = {
          _id: uuidv4(),
          message: "",
          memberId: member.id,
          type: "END_RAISE_HAND_CONNECTION",
          sent_at: parseInt(Date.now() / 100),
          user: user,
        };

        rtm
          .sendChannelMessage(chat, sessionData.channelName)
          .then(() => {
            // console.log("connection close message sent");
            toast.error("Request ended");
          })
          .catch((err) => {
            // Toast.error(
            //   "Send message to channel " +
            //     params.channelName +
            //     " failed, please open console see more details."
            // );
            console.error(err);
          });
      });
    } else {
      rtm.on("raiseHandClosed", (memberId) => {
        this.sendConnectionClosedMessageToTutor(memberId);
      });
    }

    // %% --------- Legacy handling---------- %%
    rtm.on("MemberJoin", (memberId) => {
      let { userType, sessionData, persistedData } = this.context;
      if (userType === "tutor" && sessionData.liveVersion == 1) {
        rtm
          .sendChannelMessage(
            `%USER_ID%|${persistedData.user.id}`,
            sessionData.channelName
          )
          .then(() => {
            rtm
              .sendChannelMessage(
                "%TUTOR_LIVE_FROM_WEB%",
                sessionData.channelName
              )
              .then(() => {
                console.log("sent successfully");
              });
          });
      }
    });
    // %% --------- Legacy handling---------- %%

    rtm.on("ChannelMessage", ({ channelName, args, ...rest }) => {
      let [message, memberId] = args;
      let user = this.context.getUserInfo();

      let { liveVersion } = this.context.sessionData;

      if (message.text == "%LIVE_SESSION_ENDED%") {
        return this.context.setContext({
          leaveClass: true,
        });
      }

      if (liveVersion == 1) {
        let text = message.text;
        [user, message] = text.split("%--%--%");
        // console.error(user, message);
        // old message'
        // console.error("OLD MESSAGE", message);

        if (
          text.includes("%USER_ID%|") ||
          text.includes("%TUTOR_LIVE_FROM_WEB%")
        ) {
          return;
        }
        if (
          text.includes("%STUDENT_FROM_MOBILE%") &&
          this.context.userType === "tutor"
        ) {
          let { userType, sessionData, persistedData } = this.context;
          return rtm
            .sendChannelMessage(
              `%USER_ID%|${persistedData.user.id}`,
              sessionData.channelName
            )
            .then(() => {
              rtm
                .sendChannelMessage(
                  "%TUTOR_LIVE_FROM_WEB%",
                  sessionData.channelName
                )
                .then(() => {
                  console.log("sent successfully");
                });
            });
        }
        if (
          !text.includes("%--%--%") ||
          text.includes("%STUDENT_FROM_MOBILE%") ||
          text == "%STUDENT_FROM_MOBILE%"
        ) {
          return;
        }

        return this.appendChat({
          _id: uuidv4(),
          message: message,
          user: user,
          type: "LEGACY_USER",
          sent_at: parseInt(Date.now()),
          // user: user,
        });
      } else {
        let chat = JSON.parse(message.text);
        // console.error("Chating ", chat);
        if (
          chat.type === "END_RAISE_HAND_CONNECTION" &&
          chat.memberId == user.id
        ) {
          // console.log("connection close message recieved");

          rtm.emit("closeRaiseHandConnection", {
            memberId: chat.memberId,
            sendConnectionClosedMessageToTutor: this
              .sendConnectionClosedMessageToTutor,
          });
          return;
        }
        if (chat.type === "RAISE_HAND_CONNECTION_CLOSED") {
          this.clearInvitation(chat);
          return;
        }
        console.error({ chat });
        this.appendChat(chat);
        return;
      }
    });
  }

  //

  clearInvitation = ({ memberId, ...rest }) => {
    // console.log("Clear Invitaion Member Id", { rest, memberId });
    let { members, setContext } = this.context;
    let member = members[memberId];
    if (!member) return;
    member = { ...member };
    delete member.invitation;
    delete member.invitationStatus;
    setContext({ members: { ...members, [memberId]: member } });
  };

  retryRtmConnection = () => {
    let { rtm } = this.context;
    rtm.emit("RetryRTMConnection");
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.messages && prevProps.messages.length)
      return {
        scrollHeight: this.chatContainerContentRef.current.scrollHeight,
        scrollTop: this.chatContainerContentRef.current.scrollTop,
        clientHeight: this.chatContainerContentRef.current.clientHeight,
      };
    else
      return {
        scrollHeight: 0,
        scrollTop: 0,
        clientHeight: 0,
      };
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    let { scrollHeight, scrollTop, clientHeight } =
      this.chatContainerContentRef.current || {};

    if (
      snapShot.scrollHeight - snapShot.scrollTop - snapShot.clientHeight <
        100 &&
      scrollHeight - scrollTop - clientHeight < 200
    ) {
      this.chatContainerContentRef.current.scrollTop = scrollHeight;
    }
  }

  render() {
    let { chats, connectionState, connectionStateReason } = this.state;
    // console.log("connection state");
    // console.log(connectionState, connectionStateReason);
    let user = this.context.getUserInfo();

    return (
      <div className={classes.chatContainerContent}>
        <div className={classes.chatContainerItem}>
          <ul
            className={classes.messageContainer}
            ref={this.chatContainerContentRef}
          >
            {chats.map((chat) => (
              <ChatMessage chat={chat} key={chat._id} user={user} />
            ))}
          </ul>
        </div>

        <div className={classes.chatContainerFooter}>
          <RtmConnectionStatus
            connectionState={connectionState}
            retryRtmConnection={this.retryRtmConnection}
          />
          <ChatInputWithButtons
            disabled={
              connectionState === "CONNECTED" || connectionState === null
                ? false
                : true
            }
            onSubmitHandler={this.onSubmitHandler}
          />
        </div>
      </div>
    );
  }
}
