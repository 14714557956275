import React from "react";
import moment from "moment";
import Avatar from "react-avatar";
import { Image } from "semantic-ui-react";

export default function ChatMessage(props) {
  let { chat, user } = props;

  // statically typing current user name and details
  //   let user = this.context.getUserInfo();

  if (chat.user && user.id !== chat.user.id && chat.type === "LEGACY_USER")
    return (
      <li className="chatText recievedChatContainer" key={chat._id}>
        {chat.user && <Avatar name={chat.user} className="userAvatar" />}

        <div className="left">
          <div className="senderName">{chat.user}</div>
          <div>
            <span className="chatMessageText">
              {chat.message}
              <span className="chatMessageTime">
                {moment(chat.sent_at).format("hh:mm A")}
              </span>
            </span>
          </div>
        </div>
      </li>
    );

  if (chat.user && user.id === chat.user.id && chat.type === "MESSAGE")
    return (
      <li className="chatText right" key={chat._id}>
        <span className="chatMessageText">
          {chat.message}
          <span className="chatMessageTime">
            {moment(chat.sent_at).format("hh:mm A")}
          </span>
        </span>
      </li>
    );

  if (chat.user && user.id !== chat.user.id && chat.type === "MESSAGE")
    return (
      <li className="chatText recievedChatContainer" key={chat._id}>
        {chat.user &&
          (!chat.user.imageUrl || chat.user.imageUrl === "NOT_AVAILABLE") && (
            <Avatar name={chat.user.name} className="userAvatar" />
          )}

        {chat.user &&
          chat.user.imageUrl &&
          chat.user.imageUrl !== "NOT_AVAILABLE" && (
            <Image src={chat.user.imageUrl} avatar className="userAvatar" />
          )}

        <div className="left">
          <div className="senderName">{chat.user.name}</div>
          <div>
            <span className="chatMessageText">
              {chat.message}
              <span className="chatMessageTime">
                {moment(chat.sent_at).format("hh:mm A")}
              </span>
            </span>
          </div>
        </div>
      </li>
    );

  if (chat.type === "USER_JOINED")
    return (
      <li className="chatInfo" key={chat._id}>
        <span className="success">{chat.message}</span>
      </li>
    );

  if (chat.type === "USER_LEFT")
    return (
      <li className="chatInfo" key={chat._id}>
        <span className="danger">{chat.message}</span>
      </li>
    );

  return null;
}
