import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
// import 'semantic-ui-css/semantic.min.css'
import "./index.css";
import "./App.css";
import AppContextProvider from "./context/AppContext";

import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import Chat from "./containers/chat/index";

ReactDOM.render(
  <AppContextProvider>
    <Router>
      <React.Fragment>
        <App />
      </React.Fragment>
    </Router>
  </AppContextProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
