import React, { createContext, Component } from "react";
import RtmClient from "../utils/rtm-client";

export const AppContext = createContext({});

// Provider
export default class AppContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// BASE_URL: "http://dev-testing.classplus.co",
			sessionLoaded: false,
			members: {}, // key value pair of joined members user attribute
			membersCount: 0,
			rtmConnected: false,
			rtm: new RtmClient(),
			userType: null,
			studentInvitation: {},
			activeStudentStreams: {},
			videoObjectFit: "contain",
		};

		this.setContext = this.setContext.bind(this);
		// this.updateChannelAttribute = this.updateChannelAttribute.bind(this);
		this.getUserInfo = this.getUserInfo.bind(this);
	}

	setContext(obj) {
		console.log({ obj });
		this.setState(obj, () => {
			// console.log("updatedContext+++++++", this.state);
		});
	}

	getUserInfo() {
		let { persistedData } = this.state;
		return {
			id: persistedData.user.id + "" || "NOT_AVAILABLE",
			name: persistedData.user.name || "NOT_AVAILABLE",
			imageUrl: persistedData.user.imageUrl || "NOT_AVAILABLE",
			platform: "web",
			isTutor: this.state.userType === "tutor" ? "1" : "0",
		};
	}

	// updateChannelAttribute(){

	//   let allmembers = JSON.stringify(this.state.members);

	// }

	render() {
		return (
			<AppContext.Provider
				value={{
					...this.state,
					setContext: this.setContext,
					getUserInfo: this.getUserInfo,
				}}
			>
				{this.props.children}
			</AppContext.Provider>
		);
	}
}
