export const BASE_URL =
  // "http://dev-testing.classplus.co";
  // &&
  // "https://apip.classplusapp.com";
  //  &&
  "https://api.classplusapp.com";
//  &&
// "http://dev-testing.classplus.co";
//  &&
// "https://wl-api-2.staging.classplus.co";
