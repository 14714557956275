import React, { PureComponent } from "react";
import { Tab, Loader, Menu, Icon } from "semantic-ui-react";
import Chat from "./Chat";
import { toast } from "react-toastify";
import Rooms from "./Rooms";
import { AppContext } from "../../context/AppContext";
import AudioRaiseHand from "../../utils/notification.mp3";
import { getStudentsList } from "../../apis";
export default class TabContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      // interval : null
    };
  }

  static contextType = AppContext;

  syncMemberList = async () => {
    try {
      let { sessionData, persistedData, getUserInfo, rtm } = this.context;
      let { channelName } = sessionData;
      // get list of all member ids, already on the channel
      let membersArray = await rtm.channels[channelName].channel.getMembers();

      let me = getUserInfo();

      let allStudents = {};

      // console.error({ sessionData, persistedData });

      let studentData = await getStudentsList(sessionData, persistedData);

      // console.error({ studentData });

      for (let sIndex = 0; sIndex < studentData.students.length; sIndex++) {
        let student = studentData.students[sIndex];
        allStudents[student.id] = {
          id: student.id,
          name: student.name,
          imageUrl: student.imageUrl || "NOT_AVAILABLE",
        };
      }

      if (membersArray) {
        let members = this.context.members;
        for (let i = 0; i < membersArray.length; i++) {
          let memberId = membersArray[i];
          let member = members[memberId];

          members[memberId] =
            memberId === me.id
              ? me
              : member
              ? { ...member, ...allStudents[memberId] }
              : allStudents[memberId];
        }
        this.context.setContext(
          {
            membersCount: membersArray.length,
            members: members,
            allStudents,
          },
          () => {
            // this.fetchUserDetails();
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  componentDidMount() {
    //replace with toaster

    let { userType, rtm, sessionData } = this.context;
    let { appId } = sessionData;
    window.rtm = rtm;
    rtm.init(appId, true);
    this.loginAndJoin();

    // retry connection
    rtm.on("RetryRTMConnection", () => {
      window.location.reload();
      // if (!rtm._logined) this.loginAndJoin();
      // else this.joinChannel();
    });

    if (userType === "tutor") {
      // binding rtm active streaming
      rtm.on("STREAM_ACTIVE", (userID) => {
        let { members, setContext } = this.context;
        let member = members[userID];
        if (!member) {
          member = { invitationStatus: "ACCEPTED" };
        } else {
          member = { ...member, invitationStatus: "ACCEPTED" };
        }
        // console.error("STREAM_ACTIVE_HANDING");
        // console.error(member);
        setContext({ members: { ...members, [userID]: member } });
      });

      rtm.on("STREAM_CLOSED", (userID) => {
        let { members, setContext } = this.context;
        let member = members[userID];
        if (!member) return;
        setContext({
          members: {
            ...members,
            [userID]: {
              ...member,
              invitationStatus: undefined,
              invitation: undefined,
            },
          },
        });
      });

      // to fetchPending user details every 10 seconds
      // setInterval(() => {
      //   // this.fetchUserDetails();
      //   this.fetchUserDetails();
      // }, 10000);
      // adding event listeners for handling remote invitations
      rtm.client.on("RemoteInvitationReceived", (remoteInvitation) => {
        // find callerDetails
        let { setContext } = this.context;
        let { members } = this.context;

        // ---max raise hand limit---//
        let totalActiveConnections = Object.keys(members).filter((memberId) => {
          return (
            members[memberId] &&
            members[memberId].invitationStatus === "ACCEPTED"
          );
        });

        let limitReached =
          totalActiveConnections >=
          (this.context.sessionData.activeRaiseHandLimit
            ? this.context.sessionData.activeRaiseHandLimit
            : 4)
            ? true
            : false;

        //---max raise hand limit---//

        let member = members[remoteInvitation.callerId];
        if (!member) {
          return console.error(
            "CLPS_LIVE_ERROR: Call recieved from member not it the list"
          );
        }
        if (limitReached) {
          toast.info(
            `${member.name} has also requested raise hand. End one of active student's connection to accept.`
          );
        } else toast.info(`${member.name} has requested raise hand`);

        const audioEl = document.getElementsByClassName("audio-element")[0];
        audioEl.play();

        member = { ...member };
        member.invitation = remoteInvitation;
        member.invitationStatus = "PENDING";
        setContext({
          members: { ...members, [remoteInvitation.callerId]: member },
        });

        console.log({ remoteInvitation });

        // binding cancellation event
        remoteInvitation.on("RemoteInvitationCanceled", (content) => {
          console.log("Invite cancelled");
          console.log({ content });
          let { members } = this.context;
          let member = members[remoteInvitation.callerId];
          if (!member) {
            return console.log(
              "CLPS_LIVE_ERROR: Call recieved from member not it the list"
            );
          }

          member = { ...member };
          toast.error(`${member.name} has cancelled raise hand request`);
          // toaster for caller cancelled request
          delete member.invitation;
          delete member.invitationStatus;
          setContext({
            members: { ...members, [remoteInvitation.callerId]: member },
          });
        });
      });
    }
  }

  loginAndJoin = () => {
    let { rtm, sessionData } = this.context;
    if (!sessionData) return console.log("Enable to get server data");

    let { uid, chatId } = sessionData;
    // rtm.init(appId);
    if (!uid) return console.log("UID not found");

    rtm.login(uid.toString(), chatId).then(async (data) => {
      rtm._logined = true;
      console.log("RTM LoggedIn successufully");

      try {
        // set attributes of  member
        let user = this.context.getUserInfo();
        await rtm.client.setLocalUserAttributes(user);
        // joining channel
        this.joinChannel();
      } catch (err) {
        console.log("ERROR_INFO");
        console.log(err);
      }
    });
  };

  fetchUserDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { rtm } = this.context;

        let members = this.context.members;
        let nullMembers = Object.keys(members).filter((memberId) => {
          return !members[memberId] || !members[memberId].name;
        });

        if (nullMembers.length === 0) return;

        let response = await Promise.all(
          nullMembers.map((p) =>
            rtm.client.getUserAttributes(p).catch((e) => null)
          )
        );

        let newMembers = {};
        nullMembers.map((memberId, index) => {
          newMembers[memberId] = response[index];
          return;
        });
        // console.error("Fetching user Details");

        // console.log({ response, newMembers });
        this.context.setContext({
          members: { ...this.context.members, ...newMembers },
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  // join channel
  joinChannel = () => {
    let { rtm, userType } = this.context;
    let { channelName } = this.context.sessionData;

    if (
      rtm.channels[channelName] ||
      (rtm.channels[channelName] && rtm.channels[channelName].joined)
    )
      return console.log("Already joined"); //replace with toaster

    rtm
      .joinChannel(channelName)
      .then(async () => {
        //  toaster maybe
        rtm.channels[channelName].joined = true;
        this.context.setContext({
          rtmConnected: true,
        });

        this.syncMemberList();
        if (userType === "student") {
          // get latest attendee count
          rtm.client
            .getChannelMemberCount([channelName])
            .then((currentCount) => {
              this.context.setContext({
                membersCount: currentCount[channelName],
              });
            });
        }
      })
      .catch((err) => {
        // Toast.error(
        //   "Join channel failed, please open console see more details."
        // );
        console.error(err);
      });
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  setPanes = () => {
    // console.log("setting pane");
    let panes = [
      {
        key: "chatPane",
        menuItem: (
          <Menu.Item key="chat">
            <Icon name="chat" />
            Chat
          </Menu.Item>
        ),
        pane: {
          key: "chatPane",
          content: (
            <Tab.Pane className="tabPan">
              {!this.context.rtmConnected && (
                <div className="chatTab">
                  <Loader
                    active
                    inline="centered"
                    className="loaderCenter"
                    size="small"
                  >
                    {" "}
                    Connecting...
                  </Loader>
                </div>
              )}
              {this.context.rtmConnected && <Chat />}
            </Tab.Pane>
          ),
        },
      },
    ];

    if (this.context.userType === "tutor") {
      panes.push({
        menuItem: (
          <Menu.Item key="rooms">
            <Icon name="users" />
            Classroom ({this.context.membersCount})
          </Menu.Item>
        ),
        pane: {
          key: "RoomsPane",
          content: (
            <Tab.Pane className="tabPan">
              {!this.context.rtmConnected && (
                <div className="chatTab">
                  <Loader
                    active
                    inline="centered"
                    className="loaderCenter"
                    size="small"
                  >
                    {" "}
                    Connecting...
                  </Loader>
                </div>
              )}

              {this.context.rtmConnected && <Rooms />}
            </Tab.Pane>
          ),
        },
      });
    }

    return panes;
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <React.Fragment>
        <audio className="audio-element">
          <source src={AudioRaiseHand}></source>
        </audio>

        <Tab
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          renderActiveOnly={false}
          menu={{
            color: "blue",
            secondary: true,
            pointing: true,
            attached: "top",
          }}
          panes={this.setPanes()}
        />
      </React.Fragment>
    );
  }
}
