import React, { PureComponent } from "react";
import { AppContext } from "../../context/AppContext";
import RoomUserCard from "../../components/roomUserCard";

import { v4 as uuidv4 } from "uuid";

export default class Rooms extends PureComponent {
	static contextType = AppContext;
	state = {
		members: [],
	};

	// component did mount
	componentDidMount() {
		let { setContext, rtm } = this.context;

		// attaching listners
		// rtm.on("ConnectionStateChanged", (newState, reason) => {
		//   console.log("ConnectionStateChanged");
		//   console.log({ newState, reason });
		// });

		rtm.on("MemberCountUpdated", ({ channelName, args }) => {
			let membersCount = args[0];
			setContext({ membersCount });
		});

		rtm.on("MemberJoined", async ({ channelName, args }) => {
			let { members, userType, allStudents } = this.context;

			try {
				const memberId = args[0];
				// let user = await rtm.client.getUserAttributes(memberId);
				let user = allStudents[memberId];

				if (!user || !user.name) {
					console.log("Unable to get user Attribute;");
					return;
				}

				let t = new Date();
				t.setSeconds(t.getSeconds() + 5);
				user = { ...user, joinedAt: t };

				setContext({ members: { ...members, [memberId]: user } }, function () {
					console.log("Updated context data");
					console.log(this.context);
				});

				// if tutor send Joined user name to everyone
				// if (userType === "tutor") {
				//   let messageObject = {
				//     _id: uuidv4(),
				//     message: `${user.name} Joined`,
				//     type: "USER_JOINED",
				//     user: user,
				//   };

				//   rtm.emit("appendToChat", messageObject);
				//   // rtm.sendChannelMessage(messageObject, sessionData.channelName);
				// }
			} catch (err) {
				console.log(err);
			}
		});

		rtm.on("MemberLeft", ({ channelName, args }) => {
			let { members, sessionData, userType } = this.context;
			const memberId = args[0];

			let memberAttribute;
			if (members[memberId]) {
				memberAttribute = { ...members[memberId] };
			} else {
				return;
			}

			// removing member from the  list
			delete members[memberId];

			setContext({ members: { ...members } });

			// if tutor send Joined user name to everyone
			// if (userType === "tutor" && memberAttribute) {
			//   let messageObject = {
			//     _id: uuidv4(),
			//     message: `${memberAttribute.name} Left`,
			//     type: "USER_LEFT",
			//     user: memberAttribute,
			//   };

			//   rtm.emit("appendToChat", messageObject);
			//   // rtm.sendChannelMessage(messageObject, sessionData.channelName);
			// }
		});
	}

	// user card and operations

	// userCard = (member, tutorId = null) => {};

	render() {
		let { members = {}, getUserInfo } = this.context;
		let user = getUserInfo();
		// console.log(members, "#########");

		return (
			<div className="roomsTab">
				<ul className="roomsList">
					{Object.keys(members).map((memberId) => (
						<RoomUserCard
							key={memberId}
							member={members[memberId]}
							tutorId={user.id}
							memberId={memberId}
						/>
					))}
				</ul>
			</div>
		);
	}
}

// {
//   let member = members[memberId];
//   return this.userCard({ ...member, memberId }, user.id);
// }
