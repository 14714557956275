import React, { useContext } from "react";
import classes from "../VideoPanel/VideoPanel.module.css";
import { AppContext } from "./../../context/AppContext";

function ConnectingState() {
  const appContext = useContext(AppContext);
  const { sessionStats } = appContext;
  
  return (
    <div className={classes.connectionState}>
      <div className={classes.centered}>
        <div className="text">CONNECTING</div>
        <div className="box">
          <div className="comp"></div>
          <div className="loaderConnecting"></div>
          <div className="con"></div>
          <div className="byte"></div>
          <div className="server"></div>
        </div>
      </div>
    </div>
  );
}

export default ConnectingState;
