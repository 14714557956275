import React, {
	useEffect,
	useContext,
	useRef,
	useState,
	createRef,
} from "react";
import classes from "./VideoPanel.module.css";
import { AppContext } from "./../../context/AppContext";
import AgoraClient from "./agoraHandlers";
import LiveInfo from "./LiveInfo";
import ConnectingState from "./connecting";
import { startSession, leaveChannel, getStudentsList } from "../../apis";
import { subscribeToEvents } from "./streamHandlers";
import { toast } from "react-toastify";

export default React.memo(function VideoPanel(props) {
	const appContext = useContext(AppContext);
	const initAgora = () => {
		let agoraClient = new AgoraClient();
		appContext.setContext({ agoraClient });
	};
	let mainRef = useRef();
	let sideVideoRef = useRef();
	let mainScreenRef = useRef();
	useEffect(() => {
		let videos = [];
		if (mainRef && mainRef.current) {
			videos = [...mainRef.current.querySelectorAll("video")];
		}
		if (mainScreenRef && mainScreenRef.current) {
			videos = [...videos, ...mainScreenRef.current.querySelectorAll("video")];
		}
		if (sideVideoRef && sideVideoRef.current) {
			if (sideVideoRef.current.querySelectorAll("video").length) {
				showViewsButton(true);
			} else {
				showViewsButton(false);
			}
			videos = [...videos, ...sideVideoRef.current.querySelectorAll("video")];
		}
		videos.forEach((video) => {
			let videoId = video.id.replace("video", "");
			if (appContext.localStreams && appContext.localStreams[videoId]) {
				// Some chnages can happen here
				video.muted = true;
				if (document.getElementById("audio" + videoId))
					document.getElementById("audio" + videoId).muted = true;
			} else {
				video.muted = false;
			}

			// let videoPromise = video.play();

			// if (videoPromise !== undefined) {
			//   videoPromise
			//     .then(_ => {
			//       // Autoplay started!
			//       video.controls = false;
			//       console.log("Autoplay started!");
			//     })
			//     .catch(error => {
			//       video.controls = true;
			//       video.play();
			//       toast.info(
			//         "Unable to autoplay the session. Please press the play button in the bottom left of the screen"
			//       );
			//       console.log("Autoplay not started!");
			//       // Autoplay was prevented.
			//       // Show a "Play" button so that user can start playback.
			//     });
			// }
			function playVideo() {
				if (appContext.localStreams && appContext.localStreams[videoId]) {
					appContext.localStreams[videoId].play(
						"mainVideo",
						{
							fit: appContext.videoObjectFit
								? appContext.videoObjectFit
								: "contain",
							muted: false,
						},
						function (err) {
							if (err) {
								console.error("Error in onplay", err);
								// playVideo();
							}
						}
					);
				} else if (
					appContext.remoteStreams &&
					appContext.remoteStreams[videoId]
				) {
					console.log(
						appContext && appContext.remoteStreams,
						"++++appContext.remoteStreams"
					);
					appContext.remoteStreams &&
						appContext.remoteStreams[videoId] &&
						appContext.remoteStreams[videoId].play(
							"sidevideoPanelScreen",
							{
								fit: appContext.videoObjectFit
									? appContext.videoObjectFit
									: "contain",
								muted: false,
							},
							function (err) {
								if (err) {
									//toast.error("Unable to screenshare");
									console.error("Error while playing stream in onplay", err);
									// playVideo();
								}
							}
						);
				}
			}

			if (!video.oncanplay) {
				video.oncanplay = () => {
					playVideo();
				};
			}
		});
	});
	let [connectionState, setConnectionState] = useState("CONNECTING");
	let [activeStudentContainer, showStudentVideos] = useState(false);
	let [viewsButton, showViewsButton] = useState(false);
	//
	// for testing
	window.appContext = appContext;
	//
	useEffect(() => {
		console.log("appContext", appContext);
		// if (appContext.userType === "tutor")
		initAgora();
		let { sessionData, persistedData } = appContext;
		if (appContext.userType == "student") {
			window.onbeforeunload = function (e) {
				e.preventDefault();

				window.top.postMessage("CLOSE_WINDOW_RELOAD", "*");
				//  leaveChannel(sessionData, persistedData);

				return "";
			};
		}

		// let localActiveCameraId = localStorage.getItem("activeCameraId");
		// let localActiveMicId = localStorage.getItem("activeMicId");

		// if (localActiveCameraId) {
		// 	appContext.setContext({ activeCameraId: localActiveCameraId });
		// }
		// if (localActiveMicId) {
		// 	appContext.setContext({ activeMicId: localActiveMicId });
		// }

		return () => {
			if (appContext.timerInterval) {
				clearInterval(appContext.timerInterval);
				appContext.setContext({ timerInterval: null });
			}
		};
	}, []);
	useEffect(() => {
		let { studentInvitation, agoraClient, setContext, isScreenSharing } =
			appContext;
		if (studentInvitation && studentInvitation.status == "accepted") {
			//   initAgora();
			agoraClient.createCameraStream();
		}
		if (studentInvitation && studentInvitation.status == "closed") {
			agoraClient.unpublishCameraStream();
			agoraClient.closeCameraStream();
			if (isScreenSharing) {
				agoraClient.unpublishScreenStream();
				agoraClient.closeScreenStream();
			}
		}
	}, [appContext.studentInvitation]);
	const startTimer = () => {
		const { agoraClient, timerInterval, setContext } = appContext;
		if (!timerInterval) {
			let timerInterval1 = setInterval(() => {
				let Duration = agoraClient.getSessionStats();
				console.log("Durartion-=-=-=", Duration);
				appContext.setContext({
					sessionStats: Number(Duration ? Duration : 0),
				});
			}, 1000);
			setContext({ timerInterval: timerInterval1 });
		}
	};
	useEffect(() => {
		let {
			agoraClient,
			sessionData,
			persistedData,
			userType,
			setContext,
			remoteStreams,
		} = appContext;
		console.log("agora client", agoraClient);
		if (agoraClient) {
			agoraClient.init(sessionData, persistedData, userType);
			agoraClient.on("stream-published", (evt) => {
				startTimer();
				const stream = evt.stream;
				const streamId = stream.getId();
				getStudentsList(sessionData, persistedData).then((data) => {
					console.log(" data-=-=-=-", data);
				});
				if (!sessionData.isExistingSession) {
					startSession(sessionData, persistedData);
					setContext({ sessionStarted: true });
				}
				let localStreams = appContext.localStreams
					? { ...appContext.localStreams }
					: {};
				localStreams[streamId + ""] = stream;
				// if (localStreams && localStreams[streamId + ""]) {
				//   let streamToClose = localStreams[streamId + ""];
				//   streamToClose.stop();
				//   streamToClose.close();
				// }
				setContext({ activeStreamId: streamId + "" });
				setContext({ localStreams });
				console.log(appContext.localStreams);
				if (stream.connectionSpec) {
					if (stream.connectionSpec.screen) {
						// stream.play("sidevideoPanelScreen", { fit: appContext.videoObjectFit ? appContext.videoObjectFit : "contain",muted:false }, function(
						stream.play(
							"sidevideoPanelScreen",
							{
								fit: appContext.videoObjectFit
									? appContext.videoObjectFit
									: "contain",
								muted: false,
							},
							function (err) {
								if (err) {
									//toast.error("Unable to screenshare");
									console.error("Error while playing stream", err);
								}
							}
						);
					} else {
						if (userType == "student") {
							stream.play(
								"sidevideoPanel",
								{
									fit: appContext.videoObjectFit
										? appContext.videoObjectFit
										: "contain",
									muted: false,
								},
								function (err) {
									if (err) {
										console.error("Error while playing stream ", err);
									}
								}
							);
						} else {
							stream.play(
								"mainVideo",
								{
									fit: appContext.videoObjectFit
										? appContext.videoObjectFit
										: "contain",
									muted: false,
								},
								function (err) {
									if (err) {
										console.error("Error while playing stream", err);
									}
								}
							);
						}
					}
				}
			});
			agoraClient.on("stream-added", function (evt) {
				const stream = evt.stream;
				console.log("stream-added evt", evt);
				const streamId = stream.getId();
				// Subscribe to the stream.
				setConnectionState("CONNECTED");
				agoraClient.subscribe(stream);
			});

			agoraClient.on("stream-removed", function (evt) {
				const stream = evt.stream;
				const streamId = stream.getId();
				console.log("evt", evt);

				// removing active stream invitation
				if (appContext.userType === "tutor") {
					appContext.rtm.emit("STREAM_CLOSED", stream.params.streamID);
				}

				stream.stop(); // stop the stream
				stream.close(); // clean up and close the camera stream
			});

			agoraClient.on("stream-subscribed", function (evt) {
				const stream = evt.stream;
				const streamId = stream.getId();
				let remoteStreams = appContext.remoteStreams
					? { ...appContext.remoteStreams }
					: {};
				if (remoteStreams && remoteStreams[streamId + ""]) {
					let streamToClose = remoteStreams[streamId + ""];
					streamToClose.stop();
					streamToClose.close();
				}
				remoteStreams[streamId + ""] = stream;

				// binding active stream invitaion
				if (appContext.userType === "tutor") {
					let { id } = appContext.getUserInfo();
					if (id != stream.params.streamID) {
						appContext.rtm.emit(
							"STREAM_ACTIVE",
							stream.params.streamID.toString()
						);
					}
				}

				setContext({ remoteStreams });
				if (stream.params.streamID == appContext.sessionData.tutorUserId) {
					startSession(sessionData, persistedData);
					stream.play(
						"mainVideo",
						{
							fit: appContext.videoObjectFit
								? appContext.videoObjectFit
								: "contain",
							muted: false,
						},
						function (err) {
							if (err) console.error("Error while plying stream", err);
						}
					);
					stream.unmuteAudio();
				} else
					stream.play(
						"sidevideoPanel",
						{
							fit: appContext.videoObjectFit
								? appContext.videoObjectFit
								: "contain",
							muted: false,
						},
						function (err) {
							if (err) console.error("Error while plying stream", err);
						}
					);
				stream.unmuteAudio();
			});

			agoraClient.on("peer-leave", (evt) => {
				console.log("peer-leave-=-=", evt);
				if (evt.stream) {
					evt.stream.stop();
					evt.stream.close();
					// removing active stream invitation
					if (appContext.userType === "tutor") {
						appContext.rtm.emit("STREAM_CLOSED", evt.stream.params.streamID);
					}
				}
				if (
					evt.reason == "Quit" &&
					evt.uid == appContext.sessionData.tutorUserId
				) {
					toast.info(
						"Tutor has left the session , wait for him to connect ..."
					);
					// appContext.setContext({
					//   leaveClass: true
					// });
				}
				console.log("appContext.remoteStreams", appContext.remoteStreams);
			});
			agoraClient.on("stream-unpublished", (evt) => {
				if (evt.stream) {
					evt.stream.stop();
					evt.stream.close();
					// removing active stream invitation
					if (appContext.userType === "tutor") {
						appContext.rtm.emit("STREAM_CLOSED", evt.stream.params.streamID);
					}
				}
			});
			agoraClient.on("cameraDevicesList", (evt) => {
				setContext({ cameraDevicesList: evt });
				console.log("cameraDevicesList", evt);
			});
			agoraClient.on("micDevicesList", (evt) => {
				setContext({ micDevicesList: evt });
				console.log("micDevicesList", evt);
			});
			agoraClient.on("showModal", (evt) => {
				if (evt.type == "DYNAMIC_KEY_EXPIRED") {
					appContext.setContext({
						modal: { type: "DYNAMIC_KEY_EXPIRED", hidden: false },
					});
				}
			});
			agoraClient.on("connection-state-change", (evt) => {
				if (evt.type == "DYNAMIC_KEY_EXPIRED") {
					appContext.setContext({
						modal: { type: "DYNAMIC_KEY_EXPIRED", hidden: false },
					});
				}
				if (evt.type == "connection-state-change") {
					setConnectionState(evt.curState);
					if (evt.curState == "connected") {
						debugger;
						if (appContext.isScreenSharing) {
							agoraClient.unpublishScreenStream();
							agoraClient.closeScreenStream();
							agoraClient.createScreenStream();
						} else {
							console.log("-=-=-=-=-=-=-=-=-=-=-=", evt.curState);
							agoraClient.unpublishCameraStream();
							agoraClient.closeCameraStream();
							agoraClient.createCameraStream();
						}
					}
				}
			});
			agoraClient.on("defaultIds", (evt) => {
				console.log("defaultIds", evt);
				if (evt.type == "camera") {
					setContext({
						activeCameraId: evt.id,
					});
				} else if (evt.type == "mic") {
					setContext({
						activeMicId: evt.id,
					});
				}
			});
			agoraClient.on("onTokenPrivilegeWillExpire", (evt) => {
				toast.info(
					"Your session going to expire in 30 seconds. " +
						(appContext.userType === "tutor" ? "Say your last words!" : "")
				);
			});
			agoraClient.on("onTokenPrivilegeDidExpire", (evt) => {
				toast.info("Your session duration is completed");
				appContext.setContext({
					modal: { type: "DYNAMIC_KEY_EXPIRED", hidden: false },
				});
			});
			agoraClient.on("client-banned", (evt) => {
				toast.info("The live class has been ended");
				appContext.setContext({
					modal: { type: "CLIENT_BANNED", hidden: false },
				});
			});
			agoraClient.on("AgoraError", (evt) => {
				toast.error("Not Able to access media");
				appContext.setContext({
					modal: {
						type: evt.msg == "NotAllowedError" ? "NotAllowedError" : "Error",
						hidden: false,
					},
				});
			});

			agoraClient.on("network-quality", (stats) => {
				console.log("downlinkNetworkQuality", stats.downlinkNetworkQuality);
				console.log("uplinkNetworkQuality", stats.uplinkNetworkQuality);
				appContext.setContext({
					downlinkNetworkQuality: stats.downlinkNetworkQuality,
					uplinkNetworkQuality: stats.uplinkNetworkQuality,
				});
			});
		}
	}, [appContext.agoraClient]);
	useEffect(() => {
		console.log("appContext.localStreams", appContext.localStreams);
	}, [appContext.localStreams]);
	useEffect(() => {
		console.log("appContext.remoteStreams", appContext.remoteStreams);
	}, [appContext.remoteStreams]);
	useEffect(() => {
		if (appContext.agoraClient) {
			if (appContext.isScreenSharing) {
				appContext.agoraClient.unpublishCameraStream();
				appContext.agoraClient.createScreenStream();
				toast.success("You are now presenting your screen");
			} else {
				appContext.agoraClient.unpublishScreenStream();
				appContext.agoraClient.closeScreenStream();
				appContext.agoraClient.createCameraStream();
				toast.success("You are now back to camera mode");
			}
		}
	}, [appContext.isScreenSharing]);

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}
	const prevIds = usePrevious({
		activeCameraId: appContext.activeCameraId,
		activeMicId: appContext.activeMicId,
	});

	useEffect(() => {
		if (prevIds?.activeCameraId && appContext.activeCameraId) {
			appContext.agoraClient.changeStreamSource(
				appContext.activeCameraId,
				"video"
			);
		}
	}, [appContext.activeCameraId]);
	useEffect(() => {
		if (prevIds?.activeMicId && appContext.activeMicId) {
			appContext.agoraClient.changeStreamSource(
				appContext.activeMicId,
				"audio"
			);
		}
	}, [appContext.activeMicId]);

	let { localStreams, userType, sessionData } = appContext;
	return (
		<React.Fragment>
			{connectionState === "CONNECTING" &&
				appContext.modal &&
				appContext.modal.type !== "DYNAMIC_KEY_EXPIRED" && <ConnectingState />}
			{
				<div className={classes.VideoPanel}>
					<div
						onClick={(e) => {
							console.log(e);
						}}
						ref={mainRef}
						id="mainVideo"
						className={classes.mainVideo}
					></div>
					{<LiveInfo />}
					{appContext.isScreenSharing && (
						<div
							onClick={(e) => {
								console.log(e);
							}}
							ref={mainScreenRef}
							id={"sidevideoPanelScreen"}
							className={classes.screenShareDiv}
						>
							<span className={classes.presentingNow}>
								{!appContext.isVideoSharing
									? "You are presenting your screen"
									: "You have disabled the video"}
							</span>
							<span className={classes.rippleContainer}>
								<span></span>
								<span></span>
								<span></span>
							</span>
						</div>
					)}
					{
						<React.Fragment>
							<div>
								{viewsButton && (
									<button
										className={
											activeStudentContainer
												? "showHideButton activeButton"
												: "showHideButton disabledButton"
										}
										onClick={() => {
											showStudentVideos(!activeStudentContainer);
										}}
										style={{
											visibility: sessionData.showVideo ? "visible" : "hidden",
										}}
									>
										{activeStudentContainer
											? "Show participants"
											: "Hide participants"}
									</button>
								)}
								<div
									className={
										activeStudentContainer
											? "studentVideoContainer left25"
											: "studentVideoContainer removeLeft25"
									}
									style={{
										visibility: sessionData.showVideo ? "visible" : "hidden",
									}}
								>
									<div
										onClick={(e) => {
											console.log(e);
										}}
										ref={sideVideoRef}
										id={"sidevideoPanel"}
										className={classes.sidevideoPanel}
									></div>
								</div>
							</div>
						</React.Fragment>
					}
				</div>
			}
		</React.Fragment>
	);
});
