import AgoraRTM from "agora-rtm-sdk";
import EventEmitter from "events";

export default class RTMClient extends EventEmitter {
  constructor() {
    super();
    this.channels = {};
    this._logined = false;
  }

  init(appId, enableLog = false) {
    this.client = AgoraRTM.createInstance(appId, {
      enableLogUpload: enableLog,
      logFilter: AgoraRTM.LOG_FILTER_OFF,
    });
    this.subscribeClientEvents();
  }

  // subscribe client events
  subscribeClientEvents() {
    const clientEvents = ["ConnectionStateChanged", "MessageFromPeer"];
    clientEvents.forEach((eventName) => {
      this.client.on(eventName, (...args) => {
        console.log("emit ", eventName, ...args);
        // log event message
        this.emit(eventName, ...args);
      });
    });
  }

  // subscribe channel events
  subscribeChannelEvents(channelName) {
    const channelEvents = [
      "ChannelMessage",
      "MemberJoined",
      "MemberLeft",
      "MemberCountUpdated",
    ];
    channelEvents.forEach((eventName) => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        console.log("emit ", eventName, args);
        this.emit(eventName, { channelName, args: args });
      });
    });
  }

  async login(accountName, token) {
    this.accountName = accountName;
    return this.client.login({ uid: this.accountName, token });
  }

  async logout() {
    return await this.client.logout();
  }

  async joinChannel(name) {
    console.log("joinChannel", name);
    const channel = this.client.createChannel(name);
    this.channels[name] = {
      channel,
      joined: false, // channel state
    };
    this.subscribeChannelEvents(name);
    return channel.join();
  }

  async leaveChannel(name) {
    console.log("leaveChannel", name);
    if (
      !this.channels[name] ||
      (this.channels[name] && !this.channels[name].joined)
    )
      return;
    return this.channels[name].channel.leave();
  }

  async sendChannelMessage(text, channelName) {
    let rtmMessage;
    if (typeof text !== "string") rtmMessage = { text: JSON.stringify(text) };
    else rtmMessage = { text: text };

    if (!this.channels[channelName] || !this.channels[channelName].joined) {
      throw `Join channel '${channelName}' to send message on this channel.`;
      return;
    }

    return this.channels[channelName].channel.sendMessage(rtmMessage);
  }

  async sendPeerMessage(text, peerId) {
    console.log("sendPeerMessage", text, peerId);
    return this.client.sendMessageToPeer({ text }, peerId.toString());
  }

  async queryPeersOnlineStatus(memberId) {
    console.log("queryPeersOnlineStatus", memberId);
    return this.client.queryPeersOnlineStatus([memberId]);
  }
}
