import { BASE_URL } from "./appConfig";
import { toast } from "react-toastify";

const headers = (persistedData) => {
  return {
    accept: "application/json",
    "Content-Type": "application/json",
    "Api-Version": 7,
    "x-access-token": persistedData.token,
  };
};

export const startSession = (sessionData, persistedData) => {
  fetch(
    `${BASE_URL}/v2/live/classes/session/start/${sessionData.liveSessionId}`,
    {
      method: "PATCH",
      headers: headers(persistedData),
    }
  )
    .then((data) => {
      toast.info("You are now Live!");
      // console.log("data", data);
    })
    .catch((err) => {
      alert("Error while initiating start");
      console.error("error-=-=-\n", err);
    });
};

export const leaveChannel = (sessionData, persistedData, handler) => {
  fetch(
    `${BASE_URL}/v2/live/classes/session/end/${sessionData.liveSessionId}`,
    {
      method: "PATCH",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Api-Version": 7,
        "x-access-token": persistedData.token,
      },
    }
  )
    .then((data) => {
      window.top.postMessage("CLOSE_WINDOW", "*");
      if (typeof handler == "function") {
        handler();
      }
      // console.log("data", data);
    })
    .catch((err) => {
      window.top.postMessage("CLOSE_WINDOW", "*");
      console.error("error-=-=-\n", err);
    });
};

export const getStudentsList = (sessionData, persistedData) => {
  let { type, entityId, batchCode } = sessionData;

  let batchStudentsUrl = batchCode
    ? `${BASE_URL}/v2/batches/${batchCode}/students?limit=20000&offset=0&type=current`
    : ``;
  let courseStudentsUrl = entityId
    ? `${BASE_URL}/v2/course/students/${entityId}?limit=20000&offset=0&type=${type}`
    : "";

  let studentsUrl = type == 3 ? batchStudentsUrl : courseStudentsUrl;
  return new Promise((resolve, reject) => {
    fetch(`${studentsUrl}`, {
      method: "GET",
      headers: headers(persistedData),
    })
      .then((response) => response.json())
      .then((response) => resolve(response.data))
      .catch((err) => {
        reject(err);
      });
  });
};
