import React from "react";
import Modal, {
  ModalHeader,
  ModalFooter,
  ModalBody
} from "../../components/Modal/Modal";
import classes from "../../components/Modal/Modal.module.css";

export default function LeaveForcedModal({
  hidden,
  toggle,
  handleSuccess,
  handleClose,
  showLeaving,
  body,
  appContextData,
  text
}) {
  return (
    <Modal hidden={hidden} toggle={toggle}>
      {!showLeaving ? (
        <ModalHeader>
          <p style={{ fontWeight: "medium" }}>{text}</p>
        </ModalHeader>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <ModalBody>
        {body ? body : ""}
        {!showLeaving ? (
          <div className={classes.ModalActions}>
            <button
              className={classes.btnSucceed}
              style={{ marginLeft: "16px" }}
              onClick={() => {
                handleSuccess();
                // handleClose();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <p style={{ fontWeight: "bold" }}>
            Please wait we are ending your class ...
          </p>
        )}
      </ModalBody>
      {/* <ModalFooter>Confirmation Footer</ModalFooter> */}
    </Modal>
  );
}
