import classes from "../Live.module.css";
import React from "react";
import { Loader } from "semantic-ui-react";

export default function RtmConnectionStatus(props) {
  let { connectionState, retryRtmConnection } = props;

  return connectionState ? (
    <div
      className={`${classes.connectionInfo}  ${
        connectionState !== "CONNECTED"
          ? classes.error
          : connectionState === "CONNECTED"
          ? classes.success
          : null
      } `}
    >
      {connectionState === "RECONNECTING" ? (
        <div>
          {" "}
          <Loader active inline inverted size="small" />{" "}
          <span> Reconnecting...</span>
        </div>
      ) : null}

      {connectionState === "CONNECTING" ? (
        <div>
          {" "}
          <Loader active inline inverted size="small" />{" "}
          <span> Connecting...</span>
        </div>
      ) : null}
      {connectionState === "CONNECTED" ? <div>Connected</div> : null}
      {connectionState !== "CONNECTED" && connectionState !== "RECONNECTING" ? (
        <div>
          {connectionState === "ABORTED" && (
            <span>
              Disconnected! You may have logged in from other location.{" "}
            </span>
          )}
          {connectionState !== "ABORTED" && <span>Unable to connect! </span>}
          <button className={classes.btnLinkRetry} onClick={retryRtmConnection}>
            Reload
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
}
